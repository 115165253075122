import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import tool from './utils/tool'
import http from "./utils/request"
import api from './api'

import { Dialog, Toast, Loading, Swipe, SwipeItem, List, Uploader, Popup, DatetimePicker, Picker, Sticky, Stepper, Tab, Tabs, NoticeBar } from 'vant-green'
import 'vant-green/lib/dialog/style'
import 'vant-green/lib/toast/style'
import 'vant-green/lib/swipe/style'
import 'vant-green/lib/swipe-item/style'
import 'vant-green/lib/uploader/style'
import 'vant-green/lib/popup/style'
import 'vant-green/lib/picker/style'
import 'vant-green/lib/sticky/style'
import 'vant-green/lib/stepper/style'
import 'vant-green/lib/tab/style'
import 'vant-green/lib/tabs/style'
import 'vant-green/lib/notice-bar/style'
Vue.use(Dialog)
Vue.use(Toast)
Vue.use(Loading)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(List)
Vue.use(Uploader)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(DatetimePicker)
Vue.use(Sticky)
Vue.use(Stepper)
Vue.use(Tab).use(Tabs)
Vue.use(NoticeBar)

Vue.config.productionTip = false

Vue.prototype.$TOOL = tool
Vue.prototype.$HTTP = http
Vue.prototype.$API = api


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
