import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api'

import tool from '../utils/tool'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    userInfo: {},
    websiteInfo: {},
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      if (token) {
        tool.data.set('USER_TOKEN', token)
      } else {
        tool.data.remove('USER_TOKEN')
      }
    },
    SET_USERINFO: (state, data) => {
      state.userInfo = data
      tool.data.set('APP_USER_INFO', data)
    },
    SET_WEBSITEINFO: (state, data) => {
      state.websiteInfo = data
      tool.data.set('USER_WEBSITE_INFO', data)
    }
  },
  actions: {
    async GetUserInfo({ commit }) {
      var res = await api.userInfo.post({
        Switch: 4
      })
      if (res.error == 0) {
        commit('SET_USERINFO', res.info)
      } else {
        commit('SET_TOKEN', '')
      }
      return res
    },
    async GetIsIndex() {
      var res = await api.getSet.post({
        Key: 'is_index'
      })
      return res
    },
    async GetWebsiteInfo({ commit }) {
      var res = await api.getInformation.post()
      if (res.error == 0) {
        try {
          res.info.Wx = JSON.parse(res.info.Wx)
        } catch (error) {
          res.info.Wx = { code: '', num: '' }
        }
        try {
          res.info.Contact1 = JSON.parse(res.info.Contact1)
        } catch (error) {
          res.info.Contact1 = { code: '', num: '' }
        }
        try {
          res.info.Contact2 = JSON.parse(res.info.Contact2)
        } catch (error) {
          res.info.Contact2 = { code: '', num: '' }
        }
        try {
          res.info.Contact3 = JSON.parse(res.info.Contact3)
        } catch (error) {
          res.info.Contact3 = { code: '', num: '' }
        }
        try {
          res.info.Contact4 = JSON.parse(res.info.Contact4)
        } catch (error) {
          res.info.Contact4 = { code: '', num: '' }
        }
        commit('SET_WEBSITEINFO', res.info)
        if (res.info.Ico) {
          var link = document.querySelector("link[rel~='icon']")
          if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
          }
          link.href = res.info.Ico
        }
        if (res.info.SiteKey) {
          var keywords = document.querySelector("meta[name~='keywords']")
          if (!keywords) {
            keywords = document.createElement('meta');
            keywords.name = 'keywords';
            document.getElementsByTagName('head')[0].appendChild(keywords);
          }
          keywords.content = res.info.SiteKey
        }
        if (res.info.SiteMs) {
          var description = document.querySelector("meta[name~='description']")
          if (!description) {
            description = document.createElement('meta');
            description.name = 'description';
            document.getElementsByTagName('head')[0].appendChild(description);
          }
          description.content = res.info.SiteMs
        }
      }
      return res.info
    }
  },
  modules: {
  }
})
